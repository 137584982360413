<template>
    <v-btn-toggle
        v-if="hasApiInfos && ! useDropdown"
        v-model="innerValue"
        tile
        small
        dense
        color="primary"
        group borderless mandatory
        class="!inline"
    >
        <v-btn :value="'info'" small class=" ml-0 mt-0">
            {{$t('Wishlist.detail.tabs.wish')}}
        </v-btn>
        <v-btn :value="'api'" small class=" ml-0 mt-0">
            <template v-if="isShow">
                {{$t('Wishlist.detail.tabs.show')}}
            </template>
            <template v-else>
                {{$t('Wishlist.detail.tabs.movie')}}
            </template>
        </v-btn>
        <v-btn v-if="hasCast" :value="'cast'" small class=" ml-0 mt-0">
            {{$t('Wishlist.detail.tabs.cast')}}
        </v-btn>
        <v-btn v-if="hasCrew" :value="'crew'" small class=" ml-0 mt-0">
            {{$t('Wishlist.detail.tabs.crew')}}
        </v-btn>
        <v-btn v-if="hasCompanies" :value="'company'" small class=" ml-0 mt-0">
            {{$t('Wishlist.detail.tabs.company')}}
        </v-btn>
    </v-btn-toggle>
    <div v-else-if="useDropdown && hasApiInfos">
        <v-menu v-model="visible" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    color="info"
                    x-small
                    v-on="on"
                >
                    {{ mobileName }} <v-icon x-small class="ml-1 transition transform duration-200" :class="{'rotate-180': visible}">$chevronDown</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item-group v-model="activeTab" color="primary">
                    <v-list-item key=0 @click="innerValue = 'info'">
                        <v-list-item-title>
                            {{$t('Wishlist.detail.tabs.wish')}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item key=1 @click="innerValue = 'api'">
                        <v-list-item-title>
                            <template v-if="isShow">
                                {{$t('Wishlist.detail.tabs.show')}}
                            </template>
                            <template v-else>
                                {{$t('Wishlist.detail.tabs.movie')}}
                            </template>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item key=2 v-if="hasCast" @click="innerValue = 'cast'">
                        <v-list-item-title>
                            {{$t('Wishlist.detail.tabs.cast')}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item key=3 v-if="hasCrew" @click="innerValue = 'crew'">
                        <v-list-item-title>
                            {{$t('Wishlist.detail.tabs.crew')}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item key=4 v-if="hasCompanies" @click="innerValue = 'company'">
                        <v-list-item-title>
                            {{$t('Wishlist.detail.tabs.company')}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: null,
            },

            useDropdown: {
                type: Boolean,
                default: false
            },

            hasApiInfos: {
                type: Boolean,
                default: false,
            },

            hasCast: {
                type: Boolean,
                default: false,
            },

            hasCrew: {
                type: Boolean,
                default: false,
            },

            hasCompanies: {
                type: Boolean,
                default: false,
            },

            isShow: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            innerValue: 'info',
            activeTab: 0,
            visible: false
        }),

        methods: {
            setTab() {
                switch (this.innerValue) {
                    case 'api':
                        this.activeTab = 1;
                        break;

                    case 'cast':
                        this.activeTab = 2;
                        break;

                    case 'crew':
                        this.activeTab = 3;
                        break;

                    case 'company':
                        this.activeTab = 4;
                        break;

                    default:
                        this.activeTab = 0;
                }
            }
        },

        watch: {
            innerValue(newVal) {
                this.$emit('input', newVal);
                this.setTab();
            },

            value(newVal) {
                this.innerValue = newVal;
                this.setTab();
            },
        },

        computed: {
            mobileName() {
                switch (this.innerValue) {
                    case 'api':
                        if (this.isShow) {
                            return this.$t('Wishlist.detail.tabs.show');
                        }

                        return this.$t('Wishlist.detail.tabs.movie');

                    case 'cast':
                        return this.$t('Wishlist.detail.tabs.cast');

                    case 'crew':
                        return this.$t('Wishlist.detail.tabs.crew');

                    case 'company':
                        return this.$t('Wishlist.detail.tabs.company');

                    default:
                        return this.$t('Wishlist.detail.tabs.wish');
                }
            }
        },

        created() {
            if (this.value) {
                this.innerValue = this.value;
            }

           this.setTab();
        },
    };
</script>

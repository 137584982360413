<template>
    <div>
        <v-row v-if="cast">
            <v-col
                v-for="castMember in cast"
                cols="12"
                :key="castMember.name + '-' + castMember.character"
                :xl="colsXl"
                :sm="colsSm"
                :md="colsMd"
                :lg="colsLg"
            >
                <v-tooltip
                    v-if="castMember.poster && castMember.poster !== ''"
                    left
                    allow-overflow
                    open-delay="300"
                    :disabled="isMobile"
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            v-on="on"
                            :size="96"
                        >
                            <v-img :src="castMember.poster" position="0% 20%"/>
                        </v-avatar>

                        <br />
                    </template>
                    <span>
                    <v-img :src="castMember.poster" position="top" max-width="200px"/>
                </span>
                </v-tooltip>

                <v-tooltip top :disabled="isMobile || ! getAlternativeNames(castMember)">
                    <template v-slot:activator="{ on }">
                        <div class="mt-2" v-on="on">
                            {{castMember.character}}<br>
                            {{castMember.name}}
                        </div>
                    </template>
                    <span>{{ getAlternativeNames(castMember) }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        components: {},

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            coverIsNextToDetails: {
                type: Boolean,
                default: false
            }
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({

        }),

        methods: {
            getAlternativeNames(castMember) {
                if (! castMember.alternativeNames || ! Array.isArray(castMember.alternativeNames) || castMember.alternativeNames.length < 1) {
                    return null;
                }

                return castMember.alternativeNames.join(', ');
            },
        },

        computed: {
            isMobile() {
                return this.isMobileOrTablet()
            },

            colsSm() {
                return 6;
            },

            colsMd() {
                return 4;
            },

            colsLg() {
                return 3;
            },

            colsXl() {
                return 3;
            },

            cast() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || typeof this.wish.apiDetailInfos.cast !== 'object'
                    || ! this.wish.apiDetailInfos.cast
                    || ! Array.isArray(this.wish.apiDetailInfos.cast)
                ) {
                    return null;
                }

                return this.wish.apiDetailInfos.cast;
            }
        },
    };
</script>

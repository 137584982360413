<template>
    <div>
        <v-dialog
            :value="showWithdrawWishDialog || showChangeApiDialog || showChangeStatusDialog"
            :fullscreen="$breakpoint.smAndDown"
            max-width="95%"
            :style="{'min-height:': '100%'}"
            content-class="h-full !shadow-none"
            persistent
        >
            <alert-content-card
                v-if="showWithdrawWishDialog"
                :action-button-is-loading="withdrawsWish"
                v-on:alert-component:cancel="showWithdrawWishDialog = false"
                v-on:alert-component:action="withdrawWish">
                <template slot="title">
                    {{ $t('Wishlist.detail.withdraw.title') }}
                </template>

                <template slot="actionButton">
                    {{ $t('Wishlist.detail.withdraw.withdraw') }}
                </template>

                <template slot="cancelButton">
                    {{ $t('Wishlist.detail.withdraw.cancel') }}
                </template>

                <span slot="default" v-html="$t('Wishlist.detail.withdraw.content', {wish: title})"/>
            </alert-content-card>
            <v-card flat v-else-if="showChangeApiDialog">
                <v-toolbar class="grey darken-4 !shadow-none" dense>
                    <v-toolbar-title class="flex-self-center">
                        {{$t('Wishlist.detail.changeApi.title')}}
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn @click="showChangeApiDialog = false" icon>
                        <v-icon>$close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="px-0">
                    <sticky-with-intersection>
                        <div class="w-full grey darken-4 shadow-vuetify-toolbar">
                            <v-row class="w-full px-6">
                                <v-col cols="12" sm="6" md="5">
                                    <text-field-with-validation
                                        :rules="{}"
                                        :label="$t('Wishlist.create.form.name')"
                                        v-model="changeApi.name"
                                        prepend-icon="$formName"/>
                                </v-col>

                                <v-col cols="12" sm="6" md="5">
                                    <year-picker-with-validation
                                        :label="$t('Wishlist.create.form.year')"
                                        v-model="changeApi.year"
                                        prepend-icon="$formYear"/>
                                </v-col>

                                <v-col cols="12" sm="6" md="2" class="md:flex md:items-center">
                                    <v-btn color="success" @click="changeApiEntry" :loading="changesApi" small :disabled="changeApi.apiSelected === null || changeApi.detailsLoaded === false">
                                        {{$t('Wishlist.detail.changeApi.associate')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </sticky-with-intersection>

                    <div class="px-6">
                        <media-search-core
                            ref="searchCore"
                            :year="changeApi.year"
                            :name="changeApi.name"
                            v-on:media-search:providers-loaded="loadedApiProviders"
                            v-on:media-search:result-selected="changeApi.apiSelected = $event"
                            v-on:media-search:selected-result-reset="changeApi.apiSelected = null"
                            v-on:media-search:selected-result-load-details:start="changeApi.detailsLoaded = false"
                            v-on:media-search:selected-result-load-details:finish="changeApi.detailsLoaded = true"
                            v-on:media-search:update-selected="changeApi.apiSelected = $event"
                        />
                    </div>
                </v-card-text>

                <v-divider/>

                <v-card-actions>
                    <v-btn @click="showChangeApiDialog = false" outlined small>
                        {{ $t('Schließen') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card flat v-else-if="showChangeStatusDialog">
                <v-toolbar class="grey darken-4 !shadow-none" dense>
                    <v-toolbar-title class="flex-self-center">
                        {{$t('Wishlist.detail.updateStatus.title')}}
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn @click="showChangeStatusDialog = false" icon>
                        <v-icon>$close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-form ref="form" @submit.prevent="changeStatus">
                        <v-row class="w-full">
                            <v-col cols="12" sm="6">
                                <v-select
                                    :items="statuses"
                                    :label="$t('Wishlist.detail.updateStatus.status')"
                                    v-model="statusUpdateData.status"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <date-picker-with-validation
                                    :label="$t('Wishlist.detail.updateStatus.release')"
                                    v-model="statusUpdateData.release"
                                    no-title
                                    :min="minDate"
                                    prepend-icon="$formYear"/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <text-area-with-validation
                                    :label="$t('Wishlist.detail.updateStatus.information')"
                                    rows="1"
                                    v-model="statusUpdateData.information"
                                    auto-grow
                                    prepend-icon=""/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <text-area-with-validation
                                    :label="$t('Wishlist.detail.updateStatus.internal')"
                                    rows="1"
                                    v-model="statusUpdateData.internal"
                                    auto-grow
                                    prepend-icon=""/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <text-field-with-validation
                                    label="Link"
                                    v-model="statusUpdateData.link"
                                    prepend-icon=""/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <text-field-with-validation
                                    label="Link Titel"
                                    v-model="statusUpdateData.linkTitle"
                                    prepend-icon=""/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <text-field-with-validation
                                    label="Interner Link"
                                    v-model="statusUpdateData.internalLink"
                                    prepend-icon=""/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <text-field-with-validation
                                    label="Internet Link Titel"
                                    v-model="statusUpdateData.internalLinkTitle"
                                    prepend-icon=""/>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-switch
                                    v-model="statusUpdateData.forceMail"
                                    color="success"
                                    :label="$t('Wishlist.detail.updateStatus.forceMail')"
                                    :hint="$t('Wishlist.detail.updateStatus.forceMailHint')"
                                    persistent-hint
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-btn color="success" @click="changeStatus" :loading="changingStatus" small>
                                    {{$t('Wishlist.detail.updateStatus.save')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider/>

                <v-card-actions>
                    <v-btn @click="showChangeStatusDialog = false" outlined small>
                        {{ $t('Schließen') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card>
            <sticky-with-intersection>
                <v-toolbar>
                    <v-toolbar-title class="w-full text-center">
                        <v-badge :value="actualStatus !== null && $breakpoint.mdAndUp" :content="actualStatus" :color="statusColor" inline tile>
                            {{ title }}
                        </v-badge>
                    </v-toolbar-title>

                    <div slot="extension" class="mb-2 w-full">
                        <div class="flex">
                            <tab-group
                                v-model="activeTab"
                                :has-api-infos="hasApiInfos"
                                :is-show="wish.apiType === 'show'"
                                :has-cast="hasCast"
                                :has-companies="hasCompanies"
                                :has-crew="hasCrew"
                                :use-dropdown="$breakpoint.isMobile"
                            />

                            <template v-if="withReturnButton">
                                <v-spacer v-if="hasApiInfos"></v-spacer>
                                <div>
                                    <v-btn color="warning" x-small @click="$emit('pwatcher:wishlist:details-cancel')">{{$t('Wishlist.detail.return')}}</v-btn>
                                </div>
                            </template>

                            <template v-if="showActions">
                                <v-spacer></v-spacer>
                                <action-group
                                    :can-change-hd="canChangeHd"
                                    :can-change-ov="canChangeOv"
                                    :can-change-prio="canChangePrio"
                                    :can-change-status="canChangeStatus"
                                    :can-change-api="canChangeApi"
                                    :show-withdraw="canWithdraw"
                                    :changing-flags="changingFlags"
                                    :use-dropdown="$breakpoint.isMobile"
                                    :can-follow="canFollow"
                                    :is-following="wish.isFollowing"
                                    :changing-follow="changeFollow"
                                    v-on:pwatcher:wishlist:detail-actions:withdraw-wish="showWithdrawWishDialog = true"
                                    v-on:pwatcher:wishlist:detail-actions:modify-api="showChangeApiDialog = true"
                                    v-on:pwatcher:wishlist:detail-actions:change-status="showChangeStatusDialog = true"
                                    v-on:pwatcher:wishlist:detail-actions:activate-hd="activateHdFlag"
                                    v-on:pwatcher:wishlist:detail-actions:activate-ov="activateOvFlag"
                                    v-on:pwatcher:wishlist:detail-actions:activate-prio="activatePriorityFlag"
                                    v-on:pwatcher:wishlist:detail-actions:toggle-follow="toggleFollow"
                                />
                            </template>
                        </div>

                    </div>
                </v-toolbar>
            </sticky-with-intersection>

            <v-card-text>
                <div :class="{
                    'flex': displayCoverSideBySide
                }">
                    <v-img
                        v-if="displayCoverSideBySide"
                        :max-height="maxHeight"
                        :height="maxHeight"
                        :max-width="maxWidth"
                        :width="maxWidth"
                        :lazy-src="lazyImageForCover"
                        :src="imageForCover">
                        <template v-slot:placeholder>
                            <div :style="{width: maxWidth + 'px', height: maxHeight + 'px'}">
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </div>
                        </template>
                    </v-img>
                    <div class="w-full" :class="{'ml-6': displayCoverSideBySide}">
                        <v-slide-x-transition mode="out-in">
                            <api
                                class="!animation-duration-100"
                                v-if="activeTab === 'api'"
                                :cover-is-next-to-details="displayCoverSideBySide"
                                key=2
                                :wish="wish"
                            />

                            <additional
                                class="!animation-duration-100"
                                v-else-if="activeTab === 'additional'"
                                :cover-is-next-to-details="displayCoverSideBySide"
                                key=3
                                :wish="wish"
                            />

                            <cast
                                class="!animation-duration-100"
                                v-else-if="activeTab === 'cast'"
                                :cover-is-next-to-details="displayCoverSideBySide"
                                key=4
                                :wish="wish"
                            />

                            <crew
                                class="!animation-duration-100"
                                v-else-if="activeTab === 'crew'"
                                :cover-is-next-to-details="displayCoverSideBySide"
                                key=5
                                :wish="wish"
                            />

                            <companies
                                class="!animation-duration-100"
                                v-else-if="activeTab === 'company'"
                                :cover-is-next-to-details="displayCoverSideBySide"
                                key=6
                                :wish="wish"
                            />

                            <info
                                v-else
                                key=1
                                class="!animation-duration-100"
                                :cover-is-next-to-details="displayCoverSideBySide"
                                :wish="wish"
                            />
                        </v-slide-x-transition>
                    </div>

                    <v-row
                        v-if="displayCover && ! displayCoverSideBySide"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-img
                            class="mt-5"
                            :max-height="maxHeight"
                            :height="maxHeight"
                            :max-width="maxWidth"
                            :width="maxWidth"
                            :lazy-src="lazyImageForCover"
                            :src="imageForCover">
                            <template v-slot:placeholder>
                                <div :style="{width: maxWidth + 'px', height: maxHeight + 'px'}">
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                    </v-row>
                                </div>
                            </template>
                        </v-img>
                    </v-row>
                </div>

                <template v-if="displayComments && wish.id">
                    <comments class="mt-4" module="wish" :id="wish.id" />
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Info                     from './Tabs/Info';
import Additional               from './Tabs/Additional';
import Cast                     from './Tabs/Cast';
import Crew                     from './Tabs/Crew';
import Companies                from './Tabs/Companies';
import Api                      from './Tabs/Api';
import StickyWithIntersection   from '@symfia/library/Views/Helper/StickyWithIntersection';
import TabGroup                 from './TabGroup';
import ActionGroup              from './ActionGroup';
import AlertContentCard         from '@symfia/library/Views/Components/TailwindUi/AlertContentCard';
import MediaSearchCore          from '@symfia/media/views/MediaSearch/MediaSearchCore';
import TextFieldWithValidation  from '@symfia/core/components/VeeValidate/TextFieldWithValidation';
import YearPickerWithValidation from '@symfia/core/components/VeeValidate/YearPickerWithValidation';
import TextAreaWithValidation   from '@symfia/core/components/VeeValidate/TextAreaWithValidation';
import DatePickerWithValidation from '@symfia/core/components/VeeValidate/DatePickerWithValidation';
import Comments from '@symfia/social/comments/views/Index';
import {statuses}               from '../../../Config/Status';
import HideableContentSubHeaderWithDivider from '@symfia/library/Views/Helper/HideableContentSubHeaderWithDivider.vue';

export default {
        components: {
            HideableContentSubHeaderWithDivider,
            Info,
            Api,
            Additional,
            Cast,
            Crew,
            Companies,
            StickyWithIntersection,
            TabGroup,
            ActionGroup,
            AlertContentCard,
            MediaSearchCore,
            TextFieldWithValidation,
            YearPickerWithValidation,
            TextAreaWithValidation,
            DatePickerWithValidation,
            Comments,
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            withReturnButton: {
                type:    Boolean,
                default: false,
            },

            displayComments: {
                type:    Boolean,
                default: true,
            },
        },

        mounted() {
            this.changeApi.name = this.wish.name ?? '';
            this.changeApi.year = this.wish.year ?? '';

            this.statusUpdateData.status            = this.wish.status;
            this.statusUpdateData.release           = this.wish.release ? this.wish.release : '';
            this.statusUpdateData.information       = this.wish.information;
            this.statusUpdateData.internal          = this.wish.internalInfos;
            this.statusUpdateData.link              = this.wish.linkUrl;
            this.statusUpdateData.linkTitle         = this.wish.linkTitle;
            this.statusUpdateData.internalLink      = this.wish.internalLinkUrl;
            this.statusUpdateData.internalLinkTitle = this.wish.internalLinkTitle;
        },

        beforeDestroy() {
            this.abortRequests();
        },

        watch: {
            'wish.status': function (newValue) {
                this.statusUpdateData.status = newValue;
            },

            'wish.release': function (newValue) {
                this.statusUpdateData.release = newValue ? newValue : '';
            },

            'wish.information': function (newValue) {
                this.statusUpdateData.information = newValue;
            },

            'wish.internalInfos': function (newValue) {
                this.statusUpdateData.internal = newValue;
            },

            'wish.linkUrl': function (newValue) {
                this.statusUpdateData.link = newValue;
            },

            'wish.linkTitle': function (newValue) {
                this.statusUpdateData.linkTitle = newValue;
            },

            'wish.internalLinkUrl': function (newValue) {
                this.statusUpdateData.internalLink = newValue;
            },

            'wish.internalLinkTitle': function (newValue) {
                this.statusUpdateData.internalLinkTitle = newValue;
            },
        },

        data: () => ({
            activeTab:              'info',
            showWithdrawWishDialog: false,
            showChangeApiDialog:    false,
            withdrawsWish:          false,
            changingFlags:          false,
            changeFollow:           false,
            changesApi:             false,
            changeApi:              {
                name:          '',
                year:          '',
                apiSelected:   null,
                detailsLoaded: true,
            },
            showChangeStatusDialog: false,
            changingStatus:         false,
            statusUpdateData:       {
                status:            '',
                information:       '',
                internal:          '',
                release:           '',
                link:              '',
                linkTitle:         '',
                internalLink:      '',
                internalLinkTitle: '',
                forceMail:         false,
            },
            requests:               {
                withdraw: null,
                api:      null,
                flags:    null,
                follow:   null,
                status:   null,
            },
        }),

        methods: {
            abortRequests() {
                if (this.requests.withdraw) {
                    this.requests.withdraw.cancel();
                    this.requests.withdraw = null;
                }

                if (this.requests.api) {
                    this.requests.api.cancel();
                    this.requests.api = null;
                }

                if (this.requests.flags) {
                    this.requests.flags.cancel();
                    this.requests.flags = null;
                }

                if (this.requests.follow) {
                    this.requests.follow.cancel();
                    this.requests.follow = null;
                }

                if (this.requests.status) {
                    this.requests.status.cancel();
                    this.requests.status = null;
                }
            },

            loadedApiProviders() {
                this.$nextTick(() => {
                    window.setTimeout(() => {
                        this.$refs.searchCore.triggerSearch();
                    }, 250);
                });
            },

            changeStatus() {
                if (this.changingStatus) {
                    return;
                }

                this.changingStatus  = true;
                this.requests.status = this.$axiosToken.source();

                this.$axios.put(
                    this.route('pwatcher.wishlist.change-status', {wish: this.wish.id}),
                    this.statusUpdateData,
                    {
                        cancelToken: this.requests.status.token,
                    },
                ).then((response) => {
                    this.statusUpdateData.forceMail = false;

                    this.$emit('pwatcher:wishlist:detail:wish-status-changed', response.data);
                    this.$emit('pwatcher:wishlist:detail:wish-updated', response.data);
                }).finally(() => {
                    this.changingStatus         = false;
                    this.showChangeStatusDialog = false;
                    this.requests.status        = null;
                });
            },

            withdrawWish() {
                if (this.withdrawsWish) {
                    return;
                }

                this.withdrawsWish     = true;
                this.requests.withdraw = this.$axiosToken.source();

                this.$axios.post(
                    this.route('pwatcher.wishlist.withdraw', {wish: this.wish.id}),
                    null,
                    {
                        cancelToken: this.requests.withdraw.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:detail:wish-withdrawn', response.data);
                    this.$emit('pwatcher:wishlist:detail:wish-updated', response.data);

                    /* this.$notifications.notify({
                         messageKey: 'Wishlist.detail.notifications.withdrawn',
                         type:       'success',
                     }, this);*/
                }).finally(() => {
                    this.withdrawsWish          = false;
                    this.showWithdrawWishDialog = false;
                    this.requests.withdraw      = null;
                });
            },

            activateHdFlag() {
                this.changeFlags(['hd']);
            },

            activateOvFlag() {
                this.changeFlags(['ov']);
            },

            activatePriorityFlag() {
                this.changeFlags(['priority']);
            },

            changeFlags(flags) {
                if (this.changingFlags || ! Array.isArray(flags)) {
                    return;
                }

                this.changingFlags  = true;
                this.requests.flags = this.$axiosToken.source();

                this.$axios.put(
                    this.route('pwatcher.wishlist.change-flags', {wish: this.wish.id}),
                    {
                        flags: flags,
                    },
                    {
                        cancelToken: this.requests.flags.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:detail:wish-flags-changed', response.data);
                    this.$emit('pwatcher:wishlist:detail:wish-updated', response.data);
                }).finally(() => {
                    this.changingFlags  = false;
                    this.requests.flags = null;
                });
            },

            toggleFollow() {
                if (this.changeFollow) {
                    return;
                }

                this.changeFollow  = true;
                this.requests.follow = this.$axiosToken.source();

                let route, wasFollowing = false;

                if (this.wish.isFollowing) {
                    route = 'pwatcher.wishlist.unfollow';
                    wasFollowing = true;
                } else {
                    route = 'pwatcher.wishlist.follow';
                    wasFollowing = false;
                }

                this.$axios.put(
                    this.route(
                        route,
                        {
                            wish: this.wish.id
                        }
                    ),
                    {},
                    {
                        cancelToken: this.requests.follow.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:list:wish-follow-changed', response.data);
                    this.$emit('pwatcher:wishlist:list:wish-updated', response.data);

                    if (wasFollowing) {
                        this.$tracker.trackEvent('Wünsche', 'Wunsch nicht mehr folgen - Details', this.wish.name, this.wish.id);
                    } else {
                        this.$tracker.trackEvent('Wünsche', 'Wunsch folgen - Details', this.wish.name, this.wish.id);
                    }
                }).finally(() => {
                    this.changeFollow  = false;
                    this.requests.follow = null;
                });
            },

            changeApiEntry() {
                if (this.changesApi) {
                    return;
                }

                this.changesApi   = true;
                this.requests.api = this.$axiosToken.source();

                let apiInfos      = {...this.changeApi.apiSelected};
                let detailInfos   = null;
                let originalInfos = null;

                if (typeof apiInfos.details !== 'undefined') {
                    detailInfos = apiInfos.details;
                    delete apiInfos.details;
                }

                if (typeof apiInfos.apiData !== 'undefined') {
                    originalInfos = apiInfos.apiData;
                    delete apiInfos.apiData;
                }

                this.$axios.post(
                    this.route('pwatcher.wishlist.update-api', {wish: this.wish.id}),
                    {
                        apiInfos:         apiInfos,
                        apiDetailInfos:   detailInfos,
                        apiOriginalInfos: originalInfos,
                    },
                    {
                        cancelToken: this.requests.api.token,
                    },
                ).then((response) => {
                    this.$emit('pwatcher:wishlist:detail:wish-api-changed', response.data);
                    this.$emit('pwatcher:wishlist:detail:wish-updated', response.data);
                }).finally(() => {
                    this.changesApi          = false;
                    this.showChangeApiDialog = false;
                    this.requests.api        = null;
                });
            },
        },

        computed: {
            statuses() {
                return statuses;
            },

            minDate() {
                return this.$date().add(1, 'day').format(this.$t('dates.database_date'));
            },

            canWithdraw() {
                return this.wish.owns === true && this.isActualUser(this.wish.user_id) && this.wish.statusPreparedKey !== 'closed';
            },

            canFollow() {
                return ! (this.wish.owns === true && this.isActualUser(this.wish.user_id) && this.wish.statusPreparedKey !== 'closed');
            },

            canChangeApi() {
                return (this.wish.canModerate === true) || (this.wish.statusPreparedKey !== 'closed' && this.wish.canModify === true);
            },

            canChangeHd() {
                return this.wish.statusPreparedKey !== 'closed' && this.wish.hd !== true;
            },

            canChangePrio() {
                return this.wish.statusPreparedKey !== 'closed' && this.wish.priority !== true;
            },

            canChangeOv() {
                return this.wish.statusPreparedKey !== 'closed' && this.wish.ov !== true;
            },

            canChangeStatus() {
                return this.wish.canModerate === true;
            },

            showActions() {
                return this.canChangeApi || this.canChangeHd || this.canChangePrio || this.canChangeOv || this.canChangeStatus || this.canWithdraw;
            },

            hasCast() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || typeof this.wish.apiDetailInfos.cast !== 'object'
                    || ! this.wish.apiDetailInfos.cast
                    || ! Array.isArray(this.wish.apiDetailInfos.cast)
                ) {
                    return false;
                }

                return true;
            },

            hasCrew() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || (
                        (typeof this.wish.apiDetailInfos.crew !== 'object'
                            || ! this.wish.apiDetailInfos.crew
                            || ! Array.isArray(this.wish.apiDetailInfos.crew))
                        &&
                        (typeof this.wish.apiDetailInfos.createdBy !== 'object'
                            || ! this.wish.apiDetailInfos.createdBy
                            || ! Array.isArray(this.wish.apiDetailInfos.createdBy))

                    )
                ) {
                    return false;
                }

                return true;
            },

            hasCompanies() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                ) {
                    return false;
                }

                if (typeof this.wish.apiDetailInfos.networks === 'object'
                    && this.wish.apiDetailInfos.networks
                    && Array.isArray(this.wish.apiDetailInfos.networks)
                ) {
                    return true;
                }

                if (typeof this.wish.apiDetailInfos.companies === 'object'
                    && this.wish.apiDetailInfos.companies
                    && Array.isArray(this.wish.apiDetailInfos.companies)
                ) {
                    return true;
                }

                return false;
            },

            displayCover() {
                return this.imageForCover !== null;
            },

            displayCoverSideBySide() {
                return this.displayCover && ! this.$breakpoint.isSmartphone;
            },

            useSmallLayout() {
                return this.$breakpoint.isMobileOrLargeTablet;
            },

            maxHeight() {
                if (this.$breakpoint.isMobile && ! this.$breakpoint.isTablet) {
                    //return 150;
                }

                if (this.useSmallLayout) {
                    //return 150;
                }

                return 300;
            },

            maxWidth() {
                if (this.$breakpoint.isMobile && ! this.$breakpoint.isTablet) {
                    //return 150;
                }

                if (this.useSmallLayout) {
                    //return 150;
                }

                return 200;
            },

            actualStatus() {
                if (! this.hasDetails) {
                    return null;
                }

                return this.wish.statusName && this.wish.statusName !== '' ? this.wish.statusName : null;
            },

            statusColor() {
                if (! this.hasDetails || typeof this.wish.statusPreparedKey === 'undefined') {
                    return null;
                }

                if (this.wish.statusPreparedKey === 'closed') {
                    if (typeof this.wish.wasRejected !== 'undefined' && this.wish.wasRejected === true) {
                        return 'error';
                    }

                    return 'success';
                }

                if (this.wish.statusPreparedKey === 'future') {
                    return 'warning';
                }

                return 'info';
            },

            title() {
                if (! this.hasDetails) {
                    return null;
                }

                let title = this.wish.name;

                if (this.apiExist && title !== this.wish.apiName && this.$breakpoint.mdAndUp) {
                    if (! title || title === '') {
                        title = this.wish.apiName;
                    } else {
                        title += ' (' + this.wish.apiName + ')';
                    }
                }

                if (this.year && this.year !== '') {
                    title += ' (' + this.year + ')';
                }

                return title;
            },

            year() {
                let year = '';

                if (this.wish.year && this.wish.year !== '' && this.wish.year > 0) {
                    year = this.wish.year.toString();
                }

                if (this.wish.apiYear && this.wish.apiYear !== '' && this.wish.apiYear > 0 && this.wish.apiYear.toString() !== year && this.$breakpoint.mdAndUp) {
                    if (year !== '') {
                        year += ' / ';
                    }

                    year += this.wish.apiYear.toString();
                }

                return year;
            },

            imageForCover() {
                if (! this.hasDetails) {
                    return null;
                }

                return this.wish.preferredCover;
            },

            lazyImageForCover() {
                if (! this.hasDetails) {
                    return null;
                }

                if (typeof this.wish.placeholderCoverBase64 !== 'undefined'
                    && this.wish.placeholderCoverBase64
                    && this.wish.placeholderCoverBase64 !== '') {
                    return this.wish.placeholderCoverBase64;
                }

                if (typeof this.wish.placeholderCover !== 'undefined'
                    && this.wish.placeholderCover
                    && this.wish.placeholderCover !== '') {
                    return this.wish.placeholderCover;
                }

                return null;
            },

            hasDetails() {
                return typeof this.wish === 'object' && this.wish !== null && typeof this.wish.name !== 'undefined';
            },

            hasPermissionFields() {
                if (! this.hasDetails) {
                    return false;
                }

                if (typeof this.wish.permissionInfosIncluded !== 'undefined' && this.wish.permissionInfosIncluded === true) {
                    return true;
                }

                return false;
            },

            apiExist() {
                if (! this.hasDetails) {
                    return false;
                }

                if (this.wish.apiId !== null && this.wish.apiId !== '' && this.wish.apiId !== 0) {
                    return true;
                }
                return false;
            },

            hasApiInfos() {
                if (! this.hasDetails) {
                    return false;
                }

                if (! this.apiExist) {
                    return false;
                }

                if (typeof this.wish.apiDetailsIncluded !== 'undefined' && this.wish.apiDetailsIncluded === true) {
                    return true;
                }

                return false;
            },
        },
    };
</script>

<template>
    <div>
        <v-row v-if="hasMainInfos">
            <v-col v-if="title && $breakpoint.smAndDown" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{title}}
            </v-col>
            <v-col v-if="actualStatus && $breakpoint.smAndDown" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{actualStatus}}
            </v-col>
            <v-col v-if="user" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{user}}
            </v-col>
            <v-col v-if="ovText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{ovText}}
            </v-col>
            <v-col v-if="hdText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{hdText}}
            </v-col>
            <v-col v-if="priorityText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{priorityText}}
            </v-col>
            <v-col v-if="releaseText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{releaseText}}
            </v-col>
        </v-row>

        <divider :v-if="hasMainInfos" use-margin-top :use-margin-bottom="false"/>

        <v-row class="mt-3" v-if="hasSecondaryInfos">
            <v-col v-if="typeText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{typeText}}
            </v-col>
            <v-col v-if="createdText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{createdText}}
            </v-col>
            <v-col v-if="updatedText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{updatedText}}
            </v-col>
        </v-row>

        <template v-if="additionalText">
            <divider :v-if="hasMainInfos || hasSecondaryInfos" use-margin-top :use-margin-bottom="false"/>
            <sub-header :text="$t('Wishlist.detail.additionalInfoText')" />
            <nl2br :text="additionalText" tag="div"></nl2br>
        </template>

        <template v-if="informationText">
            <divider :v-if="hasMainInfos || hasSecondaryInfos || additionalText" use-margin-top :use-margin-bottom="false"/>
            <sub-header :text="$t('Wishlist.detail.informationText')" />
            <nl2br :text="informationText" tag="div"></nl2br>
        </template>

        <template v-if="descriptionText || linkText">
            <divider :v-if="hasMainInfos || hasSecondaryInfos || additionalText || informationText" use-margin-top :use-margin-bottom="false"/>
            <sub-header :text="$t('Wishlist.detail.descriptionText')" />
            <nl2br v-if="descriptionText" :text="descriptionText" tag="div"></nl2br>
            <div v-if="linkText" v-html="linkText"></div>
        </template>

        <template v-if="internalInfoText || internalLinkText">
            <divider :v-if="hasMainInfos || hasSecondaryInfos || additionalText || informationText || descriptionText || linkText" use-margin-top :use-margin-bottom="false"/>
            <sub-header :text="$t('Wishlist.detail.internalText')" />
            <nl2br v-if="internalInfoText" :text="internalInfoText" tag="div"></nl2br>
            <div v-if="internalLinkText" v-html="internalLinkText"></div>
        </template>
    </div>
</template>

<script>
    import Divider   from '@symfia/library/Views/Helper/Divider';
    import SubHeader from '@symfia/library/Views/Helper/SubHeader';

    export default {
        components: {
            SubHeader,
            Divider
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            coverIsNextToDetails: {
                type: Boolean,
                default: false
            }
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({

        }),

        methods: {},

        computed: {
            colsSm() {
                return 6;
            },

            colsMd() {
                return 4;
            },

            colsLg() {
                return 4;
            },

            colsXl() {
                return 3;
            },

            actualStatus() {
                if (! this.hasDetails) {
                    return null;
                }

                return this.wish.statusName && this.wish.statusName !== '' ? this.wish.statusName : null;
            },

            hasMainInfos() {
                return this.user
                    || this.ovText
                    || this.hdText
                    || this.priorityText
                    || this.releaseText
                    || (this.actualStatus && this.$breakpoint.smAndDown)
                    || (this.title && this.$breakpoint.smAndDown);
            },

            title() {
                let title = this.wish.name ?? '';

                if (title !== '' && this.year && this.year !== '') {
                    title += ' (' + this.year + ')';
                }

                return title;
            },

            year() {
                let year = '';

                if (this.wish.year && this.wish.year !== '' && this.wish.year > 0) {
                    year = this.wish.year.toString();
                }

                return year;
            },

            user() {
                if (typeof this.wish.owns !== 'undefined' && this.wish.owns === true) {
                    return this.$t('Wishlist.detail.fields.from.same');
                }

                if (typeof this.wish.user !== 'object' || this.wish.user === null || ! this.wish.user.username || this.wish.user.username === '') {
                    return null;
                }

                if (this.isActualUser(this.wish.user)) {
                    return this.$t('Wishlist.detail.fields.from.same');
                }

                return this.$t('Wishlist.detail.fields.from.other', {user: this.generateUserName(this.wish.user.username)});
            },

            additionalText() {
                if (this.wish.additionalInfos && this.wish.additionalInfos !== '') {
                    return this.wish.additionalInfos;
                }

                return null;
            },

            informationText() {
                if (this.wish.information && this.wish.information !== '') {
                    return this.wish.information;
                }

                return null;
            },

            ovText() {
                if (this.wish.ov && this.wish.ov === true) {
                    return this.$t('Wishlist.detail.fields.ov');
                }

                return null;
            },

            releaseText() {
                if (this.wish.release && this.wish.release !== '') {
                    return this.$t('Wishlist.detail.fields.release', {
                        date: this.$date(this.wish.release).format(this.$t('dates.date'))
                    });
                }

                return null;
            },

            priorityText() {
                if (this.wish.priority && this.wish.priority === true) {
                    return this.$t('Wishlist.detail.fields.prio');
                }

                return null;
            },

            hdText() {
                if (this.wish.hd && this.wish.hd === true) {
                    return this.$t('Wishlist.detail.fields.hd');
                }

                return null;
            },

            hasSecondaryInfos() {
                return this.typeText || this.createdText || this.updatedText;
            },

            descriptionText() {
                if (this.wish.description && this.wish.description !== '') {
                    return this.wish.description;
                }

                return null;
            },

            linkText() {
                if (this.wish.linkUrl && this.wish.linkUrl !== '') {
                    const title = this.wish.linkTitle && this.wish.linkTitle !== '' ? this.wish.linkTitle : this.wish.linkUrl;
                    return `Url: <a href='${this.wish.linkUrl}' target="_blank">${title}</a>`
                }

                return null;
            },

            internalInfoText() {
                if (this.wish.internalInfos && this.wish.internalInfos !== '' && this.wish.canModerate === true) {
                    return this.wish.internalInfos;
                }

                return null;
            },

            internalLinkText() {
                if (this.wish.internalLinkUrl && this.wish.internalLinkUrl !== '' && this.wish.canModerate === true) {
                    const title = this.wish.internalLinkTitle && this.wish.internalLinkTitle !== '' ? this.wish.internalLinkTitle : this.wish.internalLinkUrl;
                    return `Url: <a href='${this.wish.internalLinkUrl}' target="_blank">${title}</a>`
                }

                return null;
            },

            createdText() {
                if (this.wish.created_at && this.wish.created_at !== '') {
                    return this.$t('Wishlist.detail.fields.created', {
                        date: this.$date(this.wish.created_at).format(this.$t('dates.date'))
                    });
                }

                return null;
            },

            updatedText() {
                if (this.wish.updated_at && this.wish.updated_at !== '') {
                    return this.$t('Wishlist.detail.fields.updated', {
                        date: this.$date(this.wish.updated_at).format(this.$t('dates.date'))
                    });
                }

                return null;
            },

            typeText() {
                if (this.wish.type && this.wish.typeName && this.wish.typeName !== '') {
                    return this.wish.typeName;
                }

                return null;
            },

            hasDetails() {
                return typeof this.wish === 'object' && this.wish !== null && typeof this.wish.name !== 'undefined';
            },

            apiExist() {
                if (! this.hasDetails) {
                    return false;
                }

                if (this.wish.apiId !== null && this.wish.apiId !== '' && this.wish.apiId !== 0) {
                    return true;
                }
                return false;
            },

            hasApiInfos() {
                if (! this.hasDetails) {
                    return false;
                }

                if (this.apiExist) {
                    return true;
                }

                if (typeof this.wish.apiDetailsIncluded !== 'undefined' && this.wish.apiDetailsIncluded === true) {
                    return true;
                }

                return false;
            },
        },
    };
</script>

<template>
    <div>
        <template v-if="createdBy">
            <sub-header-with-divider :text="$t('Wishlist.detail.crew.createdBy')" />
            <v-row>
                <v-col
                    v-for="crewMember in createdBy"
                    cols="12"
                    :key="crewMember.name + '-' + crewMember.job + '-' + crewMember.department"
                    :xl="colsXl"
                    :sm="colsSm"
                    :md="colsMd"
                    :lg="colsLg"
                >
                    <v-tooltip
                        v-if="crewMember.poster && crewMember.poster !== ''"
                        left
                        allow-overflow
                        open-delay="300"
                        :disabled="isMobile"
                    >
                        <template v-slot:activator="{ on }">
                            <v-avatar
                                v-on="on"
                                :size="96"
                            >
                                <v-img :src="crewMember.poster" position="0% 20%"/>
                            </v-avatar>

                            <br/>
                        </template>
                        <span>
                    <v-img :src="crewMember.poster" position="top" max-width="200px"/>
                </span>
                    </v-tooltip>
                    <div class="mt-2">
                        {{crewMember.name}}
                        <br v-if="getJobAndDepartment(crewMember)"> {{getJobAndDepartment(crewMember)}}
                    </div>
                </v-col>
            </v-row>
        </template>

        <template v-if="crew">
            <sub-header-with-divider :text="$t('Wishlist.detail.crew.crew')" />
            <v-row>
                <v-col
                    v-for="crewMember in crew"
                    :key="crewMember.name + '-' + crewMember.job + '-' + crewMember.department"
                    cols="12"
                    :xl="colsXl"
                    :sm="colsSm"
                    :md="colsMd"
                    :lg="colsLg"
                >
                    <v-tooltip
                        v-if="crewMember.poster && crewMember.poster !== ''"
                        left
                        allow-overflow
                        open-delay="300"
                        :disabled="isMobile"
                    >
                        <template v-slot:activator="{ on }">
                            <v-avatar
                                v-on="on"
                                :size="96"
                            >
                                <v-img :src="crewMember.poster" position="0% 20%"/>
                            </v-avatar>

                            <br/>
                        </template>
                        <span>
                    <v-img :src="crewMember.poster" position="top" max-width="200px"/>
                </span>
                    </v-tooltip>
                    <div class="mt-2">
                        {{crewMember.name}}
                        <br v-if="getJobAndDepartment(crewMember)"> {{getJobAndDepartment(crewMember)}}
                    </div>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';

    export default {
        components: {
            SubHeaderWithDivider
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            coverIsNextToDetails: {
                type:    Boolean,
                default: false,
            },
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({}),

        methods: {
            getJobAndDepartment(crewMember) {
                let jobAndDepartment = null;

                if (crewMember.job && crewMember.job !== '') {
                    jobAndDepartment = crewMember.job;
                }

                if (crewMember.department && crewMember.department !== '') {
                    if (jobAndDepartment && jobAndDepartment !== '') {
                        jobAndDepartment += ' (' + crewMember.department + ')';
                    } else {
                        jobAndDepartment = crewMember.department;
                    }
                }

                return jobAndDepartment;
            },
        },

        computed: {
            isMobile() {
                return this.isMobileOrTablet();
            },

            colsSm() {
                return 6;
            },

            colsMd() {
                return 4;
            },

            colsLg() {
                return 3;
            },

            colsXl() {
                return 3;
            },

            crew() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || typeof this.wish.apiDetailInfos.crew !== 'object'
                    || ! this.wish.apiDetailInfos.crew
                    || ! Array.isArray(this.wish.apiDetailInfos.crew)
                ) {
                    return null;
                }

                return this.wish.apiDetailInfos.crew;
            },

            createdBy() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || typeof this.wish.apiDetailInfos.crew !== 'object'
                    || ! this.wish.apiDetailInfos.createdBy
                    || ! Array.isArray(this.wish.apiDetailInfos.createdBy)
                ) {
                    return null;
                }

                return this.wish.apiDetailInfos.createdBy;
            },
        },
    };
</script>

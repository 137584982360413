<template>
    <div>
        <v-row class="mt-3" v-if="hasSecondaryInfos">
            <v-col v-if="typeText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{typeText}}
            </v-col>
            <v-col v-if="createdText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{createdText}}
            </v-col>
            <v-col v-if="updatedText" cols="12" :xl="colsXl" :sm="colsSm" :md="colsMd" :lg="colsLg">
                {{updatedText}}
            </v-col>
        </v-row>

        <template v-if="descriptionText">
            <divider :v-if="hasSecondaryInfos" use-margin-top :use-margin-bottom="false"/>
            <sub-header :text="$t('Wishlist.detail.descriptionText')" />
            <nl2br :text="descriptionText" tag="div"></nl2br>
        </template>
    </div>
</template>

<script>
    import Divider   from '@symfia/library/Views/Helper/Divider';
    import SubHeader from '@symfia/library/Views/Helper/SubHeader';

    export default {
        components: {
            SubHeader,
            Divider
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            coverIsNextToDetails: {
                type: Boolean,
                default: false
            }
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({

        }),

        methods: {},

        computed: {
            colsSm() {
                return 6;
            },

            colsMd() {
                return 4;
            },

            colsLg() {
                return 4;
            },

            colsXl() {
                return 3;
            },

            hasSecondaryInfos() {
                return this.typeText || this.createdText || this.updatedText;
            },

            descriptionText() {
                if (this.wish.description && this.wish.description !== '') {
                    return this.wish.description;
                }

                return null;
            },

            createdText() {
                if (this.wish.created_at && this.wish.created_at !== '') {
                    return this.$t('Wishlist.detail.fields.created', {
                        date: this.$date(this.wish.created_at).format(this.$t('dates.date'))
                    });
                }

                return null;
            },

            updatedText() {
                if (this.wish.updated_at && this.wish.updated_at !== '') {
                    return this.$t('Wishlist.detail.fields.updated', {
                        date: this.$date(this.wish.updated_at).format(this.$t('dates.date'))
                    });
                }

                return null;
            },

            typeText() {
                if (this.wish.type && this.wish.typeName && this.wish.typeName !== '') {
                    return this.wish.typeName;
                }

                return null;
            },
        },
    };
</script>

<template>
    <div>
        <v-boilerplate-loading v-if="! hasDetails" type="list-item-three-line@6" class="w-full"/>
        <div class="position-relative" v-else>
            <v-alert type="info" v-if="loading" outlined class="mb-3" dense icon="$spinner">{{ $t('Wishlist.detail.loadingInfos') }}</v-alert>
            <wish-details
                with-return-button
                v-on:pwatcher:wishlist:details-cancel="redirectToList"
                v-on:pwatcher:wishlist:detail:wish-updated="wishChanged"
                :display-comments="displayComments"
                :wish="this.wish"/>
        </div>
    </div>
</template>

<script>
    import WishDetails from './Helper/Detail/WishDetails';

    export default {
        components: {
            WishDetails,
        },

        props: {
            preloadedWish: {
                type:    Object,
                default: null,
            },

            noRedirect: {
                type:    Boolean,
                default: false,
            },

            comesFromRoute: {
                type:    String,
                default: '/wishes/open',
            },

            displayComments: {
                type:    Boolean,
                default: true,
            },
        },

        mounted() {
            this.wish = this.preloadedWish;

            this.loadWishIfNeeded();

            this.$echo.listen(
                'Wish',
                '.Plugins\\PWatcher\\Wishlist\\Events\\WishUpdated',
                this.changeWishFromPusher,
                'pwatcher-wishlist-detail',
            );
        },

        beforeDestroy() {
            this.abortRequest();

            this.$echo.stopListening(
                'Wish',
                '.Plugins\\PWatcher\\Wishlist\\Events\\WishUpdated',
                this.changeWishFromPusher,
                'pwatcher-wishlist-detail',
            );
        },

        watch: {
            $route(to, from) {
                this.wish = null;
                this.loadWish();
            },
        },

        data: () => ({
            wish:        null,
            loading:     false,
            loadingMore: false,
            axios:       null,
        }),

        methods: {
            wishChanged(wish) {
                this.$logger.debug(
                    'Wish changed event',
                    wish,
                    'Wishlist-Detail',
                );

                this.wish = wish;

                this.$emit('pwatcher:wishlist:detail:wish-updated', wish);
            },

            changeWishFromPusher(wish) {
                this.$logger.debug(
                    'Modify wish from update',
                    wish,
                    'Wishlist-Detail',
                );

                if (this.wish && this.wish.id === wish.id) {
                    let permissionInfosIncluded = this.wish.permissionInfosIncluded ? this.wish.permissionInfosIncluded : false;
                    let apiDetailsIncluded      = this.wish.apiDetailsIncluded ? this.wish.apiDetailsIncluded : false;
                    let allApiInfosIncluded     = this.wish.allApiInfosIncluded ? this.wish.allApiInfosIncluded : false;

                    const combinedWish = {
                        ...this.wish,
                        ...wish,
                    };

                    combinedWish.permissionInfosIncluded = (wish.permissionInfosIncluded === true ? true : permissionInfosIncluded);
                    combinedWish.apiDetailsIncluded      = (wish.apiDetailsIncluded === true ? true : apiDetailsIncluded);
                    combinedWish.allApiInfosIncluded     = (wish.allApiInfosIncluded === true ? true : allApiInfosIncluded);

                    this.$logger.debug(
                        'Modify wish from update - combine',
                        {
                            combinedWish,
                            old: this.wish,
                            new: wish,
                        },
                        'Wishlist-Detail',
                    );

                    this.wish = combinedWish;
                    this.abortRequest();
                    this.loadWishIfNeeded();
                }
            },

            abortRequest() {
                if (this.axios) {
                    this.axios.cancel();
                    this.axios = null;
                }
            },

            loadWishIfNeeded() {
                this.$nextTick(() => {
                    if (! this.hasDetails || ! this.hasApiInfos || ! this.hasPermissionFields) {
                        this.loadWish(this.hasDetails === true);
                    }
                });
            },

            loadWish(isLoadingMoreData = false) {
                if (this.loading || this.loadingMore || ! this.usedWishId) {
                    return;
                }

                if (isLoadingMoreData === true) {
                    this.loadingMore = true;
                } else {
                    this.loading = true;
                }

                this.axios = this.$axiosToken.source();

                this.$axios.get(
                    this.route('pwatcher.wishlist.details', {id: this.usedWishId}),
                    {
                        cancelToken: this.axios.token,
                    },
                ).then((response) => {
                    this.wish = response.data;
                }).finally(() => {
                    this.loading     = false;
                    this.loadingMore = false;

                    this.axios = null;
                });
            },

            redirectToList() {
                if (this.noRedirect !== true) {
                    let route = this.comesFromRoute && this.comesFromRoute !== '' ? this.comesFromRoute : '/wishes/open';

                    if (this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
                        route = this.$route.query.redirect;
                    }

                    this.$router.push(route);
                } else {
                    this.$emit('pwatcher:wishlist:details-cancel');
                }
            },
        },

        computed: {
            hasDetails() {
                return typeof this.wish === 'object' && this.wish !== null && typeof this.wish.name !== 'undefined';
            },

            hasPermissionFields() {
                if (! this.hasDetails) {
                    return false;
                }

                if (typeof this.wish.permissionInfosIncluded !== 'undefined' && this.wish.permissionInfosIncluded === true) {
                    return true;
                }

                return false;
            },

            apiExist() {
                if (! this.hasDetails) {
                    return false;
                }

                if (this.wish.apiId !== null && this.wish.apiId !== '' && this.wish.apiId !== 0) {
                    return true;
                }
                return false;
            },

            hasApiInfos() {
                if (! this.hasDetails) {
                    return false;
                }

                if (this.apiExist) {
                    return true;
                }

                if (typeof this.wish.apiDetailsIncluded !== 'undefined' && this.wish.apiDetailsIncluded === true) {
                    return true;
                }

                return false;
            },

            usedWishId() {
                if (typeof this.wish === 'object'
                    && this.wish !== null && this.wish.id) {
                    return this.wish.id;
                }

                if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== '') {
                    return this.$route.params.id;
                }

                return null;
            },
        },
    };
</script>

<template>
    <div>
        <template v-if="networks">
            <sub-header-with-divider :text="$t('Wishlist.detail.company.networks')" />
            <v-row>
                <v-col
                    v-for="network in networks"
                    :key="network.name"
                    cols="12"
                    :xl="colsXl"
                    :sm="colsSm"
                    :md="colsMd"
                    :lg="colsLg"
                >
                    <v-tooltip
                        v-if="network.poster && network.poster !== ''"
                        left
                        allow-overflow
                        open-delay="300"
                        :disabled="isMobile"
                    >
                        <template v-slot:activator="{ on }">
                            <v-avatar
                                v-on="on"
                                :size="96"
                                color="white"
                            >
                                <v-img max-width="96" max-height="96" width="96" position="0% 50%" contain :src="network.poster" />
                            </v-avatar>

                            <br/>
                        </template>
                        <span>
                            <v-img :src="network.poster" position="top" max-width="200px"/>
                        </span>
                    </v-tooltip>
                    <div>
                        {{network.name}} <template v-if="network.country && network.country !== ''">({{network.country}})</template>
                    </div>
                </v-col>
            </v-row>
        </template>

        <template v-if="companies">
            <sub-header-with-divider :text="$t('Wishlist.detail.company.companies')" />
            <v-row>
                <v-col
                    v-for="company in companies"
                    :key="company.name"
                    cols="12"
                    :xl="colsXl"
                    :sm="colsSm"
                    :md="colsMd"
                    :lg="colsLg"
                >
                    <v-tooltip
                        v-if="company.poster && company.poster !== ''"
                        left
                        allow-overflow
                        open-delay="300"
                        :disabled="isMobile"
                    >
                        <template v-slot:activator="{ on }">
                            <v-avatar
                                v-on="on"
                                :size="96"
                                color="white"
                            >
                                <v-img max-width="96" max-height="96" width="96" position="0% 50%" contain :src="company.poster" />
                            </v-avatar>

                            <br/>
                        </template>
                        <span>
                            <v-img :src="company.poster" position="top" max-width="200px"/>
                        </span>
                    </v-tooltip>
                    <div>
                        {{company.name}}
                    </div>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
    import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';

    export default {
        components: {
            SubHeaderWithDivider
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            coverIsNextToDetails: {
                type:    Boolean,
                default: false,
            },
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({}),

        methods: {
            getJobAndDepartment(crewMember) {
                let jobAndDepartment = null;

                if (crewMember.job && crewMember.job !== '') {
                    jobAndDepartment = crewMember.job;
                }

                if (crewMember.department && crewMember.department !== '') {
                    if (jobAndDepartment && jobAndDepartment !== '') {
                        jobAndDepartment += ' (' + crewMember.department + ')';
                    } else {
                        jobAndDepartment = crewMember.department;
                    }
                }

                return jobAndDepartment;
            },
        },

        computed: {
            isMobile() {
                return this.isMobileOrTablet();
            },

            colsSm() {
                return 6;
            },

            colsMd() {
                return 4;
            },

            colsLg() {
                return 3;
            },

            colsXl() {
                return 3;
            },

            networks() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || typeof this.wish.apiDetailInfos.networks !== 'object'
                    || ! this.wish.apiDetailInfos.networks
                    || ! Array.isArray(this.wish.apiDetailInfos.networks)
                ) {
                    return null;
                }

                return this.wish.apiDetailInfos.networks;
            },

            companies() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || typeof this.wish.apiDetailInfos.companies !== 'object'
                    || ! this.wish.apiDetailInfos.companies
                    || ! Array.isArray(this.wish.apiDetailInfos.companies)
                ) {
                    return null;
                }

                return this.wish.apiDetailInfos.companies;
            },
        },
    };
</script>

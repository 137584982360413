<template>
    <div>
        <v-img
            v-if="imageForBanner"
            :max-height="maxHeight"
            :height="maxHeight"
            :lazy-src="lazyImageForBanner"
            :position="backgroundPosition"
            width="100%"
            :src="imageForBanner">
            <template v-slot:placeholder>
                <div :style="{height: maxHeight + 'px'}" class="-mt-12">
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </div>
            </template>
        </v-img>

        <div :class="{
            '-mt-16': imageForBanner,
            'mr-5': imageForBanner,
            'ml-5': imageForBanner
        }">
            <wish-content-card
                v-on:pwatcher:wishlist:details-cancel="$emit('pwatcher:wishlist:details-cancel')"
                v-on:pwatcher:wishlist:detail:wish-updated="wishUpdated"
                :with-return-button="withReturnButton"
                :display-comments="displayComments"
                :wish="wish"/>
        </div>

    </div>
</template>

<script>
    import WishContentCard from './WishContentCard';

    export default {
        components: {
            WishContentCard,
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            withReturnButton: {
                type:    Boolean,
                default: false,
            },

            displayComments: {
                type:    Boolean,
                default: true,
            },
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({}),

        methods: {
            wishUpdated(wish) {
                this.$emit('pwatcher:wishlist:detail:wish-updated', wish);
            }
        },

        computed: {
            maxHeight() {
                if (this.$breakpoint.isMobileOrLargeTablet) {
                    if (this.$breakpoint.isMobile) {
                        return 150;
                    }

                    return 200;
                }

                return 250;
            },

            backgroundPosition() {
                if (this.usesBackgroundAsBanner) {
                    return '0 15%';
                }

                return 'center center';
            },

            usesBackgroundAsBanner() {
                if (! this.hasDetails) {
                    return false;
                }

                return this.wish.imageForBanner !== null && this.wish.preferredBanner === null;
            },

            imageForBanner() {
                if (! this.hasDetails) {
                    return null;
                }

                return this.wish.preferredImageForBanner;
            },

            lazyImageForBanner() {
                if (! this.hasDetails) {
                    return null;
                }

                if (typeof this.wish.preferredPlaceholderImageForBannerBase64 !== 'undefined'
                    && this.wish.preferredPlaceholderImageForBannerBase64
                    && this.wish.preferredPlaceholderImageForBannerBase64 !== '') {
                    return this.wish.preferredPlaceholderImageForBannerBase64;
                }

                if (typeof this.wish.preferredPlaceholderImageForBanner !== 'undefined'
                    && this.wish.preferredPlaceholderImageForBanner
                    && this.wish.preferredPlaceholderImageForBanner !== '') {
                    return this.wish.preferredPlaceholderImageForBanner;
                }

                return null;
            },

            hasDetails() {
                return typeof this.wish === 'object' && this.wish !== null && typeof this.wish.name !== 'undefined';
            },
        },
    };
</script>

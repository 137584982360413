<template>
    <div>
        <v-row v-if="hasInfos">
            <v-col v-if="title && title !== '' && $breakpoint.smAndDown" cols="12" sm="6">
                {{title}}
            </v-col>
            <v-col v-if="source" cols="12" sm="6">
                {{source}}
            </v-col>
            <v-col v-if="type" cols="12" sm="6" class="sm:text-right">
                {{type}}
            </v-col>
        </v-row>

        <v-row v-if="hasSecondaryInfos">
            <v-col v-if="genres" cols="12" sm="6">
                {{genres}}
            </v-col>

            <v-col v-if="futureText" cols="12" sm="6" md="3">
                {{ futureText }}
            </v-col>

            <v-col v-if="vote" cols="12" sm="6" md="3" :offset-sm="offsetVoteSm" :offset-md="offsetVoteMd" class="sm:text-right">
                <v-rating small readonly color="info" :value="vote"/>
            </v-col>
        </v-row>

        <v-row v-if="hasThirdInfos">
            <v-col v-if="runtime" cols="12" :sm="colsSm" :md="colsMd" :lg="colsLg" :xl="colsXl">
                {{ runtime }}
            </v-col>
            <v-col v-if="seasonsAndEpisodes" cols="12" :sm="colsSm" :md="colsMd" :lg="colsLg" :xl="colsXl" v-html="seasonsAndEpisodes" />
            <v-col v-if="keywords" cols="12" :sm="colsSm" :md="colsMd" :lg="colsLg" :xl="colsXl">
                <sub-header :text="$t('Wishlist.detail.fields.keywords')"/>
                <v-row>
                    <v-col v-for="keyword in keywords" :key="keyword" cols="12" xs="4" sm="6" lg="4">{{keyword}}</v-col>
                </v-row>
            </v-col>
            <v-col v-if="aliases" cols="12" :sm="colsSm" :md="colsMd" :lg="colsLg" :xl="colsXl">
                <sub-header :text="$t('Wishlist.detail.fields.aliases')"/>
                <v-row>
                    <v-col v-for="alias in aliases" :key="alias" cols="12" xs="4" sm="6" lg="4">{{alias}}</v-col>
                </v-row>
            </v-col>
        </v-row>

        <template v-if="hasDescription">
            <divider :v-if="hasInfos || hasSecondaryInfos || hasThirdInfos" use-margin-top :use-margin-bottom="false"/>
            <sub-header :text="$t('Wishlist.detail.apiDescriptionText')"/>
            <nl2br :text="wish.apiDesc" tag="div"></nl2br>
        </template>
    </div>
</template>

<script>
    import Divider   from '@symfia/library/Views/Helper/Divider';
    import SubHeader from '@symfia/library/Views/Helper/SubHeader';

    export default {
        components: {
            SubHeader,
            Divider,
        },

        props: {
            wish: {
                type:    Object,
                default: null,
            },

            coverIsNextToDetails: {
                type:    Boolean,
                default: false,
            },
        },

        mounted() {

        },

        beforeDestroy() {

        },

        watch: {},

        data: () => ({}),

        methods: {},

        computed: {
            offsetVoteSm() {
                let offset = 0;

                if (! this.genres) {
                    offset += 6;
                }

                if (! this.futureText) {
                    offset += 6;
                }

                return offset;
            },

            offsetVoteMd() {
                let offset = 0;

                if (! this.genres) {
                    offset += 6;
                }

                if (! this.futureText) {
                    offset += 3;
                }

                return offset;
            },

            title() {
                let title = '';

                if (this.wish.apiName) {
                        title = this.wish.apiName;
                }

                if (this.year && this.year !== '') {
                    title += ' (' + this.year + ')';
                }

                return title;
            },

            year() {
                let year = '';

                if (this.wish.apiYear && this.wish.apiYear !== '' && this.wish.apiYear > 0 && this.wish.apiYear.toString() !== year) {
                    year += this.wish.apiYear.toString();
                }

                return year;
            },


            hasInfos() {
                return this.source || this.type || (this.title && this.title !== '' && this.$breakpoint.smAndDown);
            },

            hasSecondaryInfos() {
                return this.futureText || this.vote || this.genres;
            },

            hasThirdInfos() {
                return this.runtime || this.aliases || this.keywords || this.seasonsAndEpisodes;
            },

            runtime() {
                if (typeof this.wish.apiDetailInfos === 'object'
                    && this.wish.apiDetailInfos
                    && this.wish.apiDetailInfos.runtime
                    && this.wish.apiDetailInfos.runtime !== 0
                    && this.wish.apiDetailInfos.runtime !== '') {
                    if (this.wish.apiType === 'show') {
                        return this.$t('Wishlist.detail.fields.runtime.show', {
                            time: this.wish.apiDetailInfos.runtime,
                        });
                    }

                    return this.$t('Wishlist.detail.fields.runtime.movie', {
                        time: this.wish.apiDetailInfos.runtime,
                    });
                }

                return null;
            },

            seasonsAndEpisodes() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || this.wish.apiType !== 'show') {
                    return 0;
                }

                let text = [];

                if (this.wish.apiDetailInfos.seasonsCount
                    && this.wish.apiDetailInfos.seasonsCount > 0) {
                    text.push(
                        this.wish.apiDetailInfos.seasonsCount === 1
                            ? this.$t('Wishlist.detail.fields.seasons.one')
                            : this.$t('Wishlist.detail.fields.seasons.multiple', {
                                count: this.wish.apiDetailInfos.seasonsCount
                            }),
                    );
                }

                if (this.wish.apiDetailInfos.episodesCount
                    && this.wish.apiDetailInfos.episodesCount > 0) {
                    text.push(
                        this.wish.apiDetailInfos.episodesCount === 1
                            ? this.$t('Wishlist.detail.fields.episodes.one')
                            : this.$t('Wishlist.detail.fields.episodes.multiple', {
                                count: this.wish.apiDetailInfos.episodesCount
                            })
                    );
                }

                if (text.length === 0) {
                    return null;
                }

                text.push(
                    '('+this.$t('Wishlist.detail.seasonsAndEpisodesAtCreation')+')'
                );

                return text.join('<br/>');
            },

            vote() {
                if (typeof this.wish.apiInfos === 'object'
                    && this.wish.apiInfos
                    && this.wish.apiInfos.averageVote
                    && this.wish.apiInfos.averageVote !== '') {
                    let vote = this.wish.apiInfos.averageVote;

                    if (vote > 10) {
                        vote = 5;
                    } else if (vote >= 6) {
                        vote /= 2;
                    }

                    return vote;
                }

                return null;
            },

            keywords() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || ! this.wish.apiDetailInfos
                    || ! Array.isArray(this.wish.apiDetailInfos.keywords)) {
                    return null;
                }

                return this.wish.apiDetailInfos.keywords;
            },

            aliases() {
                if (typeof this.wish.apiDetailInfos !== 'object'
                    || this.wish.apiDetailInfos
                    || ! Array.isArray(this.wish.apiDetailInfos.aliases)) {
                    return null;
                }

                return this.wish.apiDetailInfos.aliases;
            },

            genres() {
                if (typeof this.wish.apiGenres !== 'object' || ! Array.isArray(this.wish.apiGenres)) {
                    return null;
                }

                return this.wish.apiGenres.join(', ');
            },

            hasDescription() {
                return this.wish.apiDesc && this.wish.apiDesc !== '';
            },

            futureText() {
                if (typeof this.wish.apiInfos === 'object' && this.wish.apiInfos && this.wish.apiInfos.release !== '') {
                    let parsedRelease = this.$date(this.wish.apiInfos.release);

                    if (parsedRelease && parsedRelease.isAfter(this.$date())) {
                        return this.$t('Wishlist.detail.fields.futureReleaseApi', {
                            date: this.$date(this.wish.release).format(this.$t('dates.date')),
                        });
                    }
                }

                return null;
            },

            source() {
                if (this.wish.apiSourceFullName && this.wish.apiSourceFullName !== '') {
                    return this.wish.apiSourceFullName;
                }

                return null;
            },

            type() {
                let typeName = null;

                if (this.wish.apiTypeName && this.wish.apiTypeName !== '') {
                    typeName = this.wish.apiTypeName;
                }

                if (this.wish.apiSubTypeName && this.wish.apiSubTypeName !== '') {
                    if (typeName) {
                        typeName += ' / ' + this.wish.apiSubTypeName;
                    } else {
                        typeName = this.wish.apiSubTypeName;
                    }
                }

                return typeName;
            },

            colsSm() {
                return 6;
            },

            colsMd() {
                return 4;
            },

            colsLg() {
                return 4;
            },

            colsXl() {
                return 3;
            },
        },
    };
</script>
